import Stack from '@mui/material/Stack';
import HeroBanner from 'components/HeroBanner';
import HeroBannerLogo from 'components/HeroBanner/HeroBannerLogo';
import HeroBannerHeadline from 'components/HeroBanner/HeroBannerHeadline';
import HeroBannerButton from 'components/HeroBanner/HeroBannerButton';
import { logo_hw_cz_outlined, czech_home_banner } from 'config/images';
import { appData } from 'config/data.cjs';

const { messages } = appData;

const LogoProps = {
	width: {xs: 150, sm: 180, md: 300}
};
const HeroBannerProps = {
	sx: {
		'&&': {
			maxHeight: {xs: 220, desktop: 250},
			minHeight: {xs: 220, desktop: 250}
		}
	}
};

export default function CzechHomeHeroBanner() {
	return (
		<HeroBanner {...HeroBannerProps} image={czech_home_banner} overlay>
			<HeroBannerLogo ImgProps={logo_hw_cz_outlined} LogoProps={LogoProps} justifyContent="space-around">
				<Stack gap={{xs: 1, md: 2, lg: 3}}>
					<HeroBannerHeadline
						gutterTop={false}
						title={messages.pages.home.heroBanner.title}
						subtitle={messages.pages.home.heroBanner.subtitle}
						component="h1"
					/>
					<HeroBannerButton href={appData.pages.assortment.pathname}>
						{messages.button.heroHome}
					</HeroBannerButton>
				</Stack>
			</HeroBannerLogo>
		</HeroBanner>
	);
}
