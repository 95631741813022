import { useOutletContext } from 'react-router-dom';
import HtmlParser from 'react-app/components/HtmlParser';
import AppContent from 'components/AppContent';
import PageHeadline from 'components/PageHeadline';
import PageContainer from 'components/PageContainer';
import NewsSection from 'components/News/NewsSection';
import ImageGalleryTabs, { useImageGalleryTabs } from 'components/ImageGalleryTabs';
import HeroBanner from './CzechHomeHeroBanner';
import ContactSection from './CzechContactSection';
import { appData } from 'config/data.cjs';

const { messages } = appData;

export default function CzechHomeRoute() {
	const { title, headline, subtitle, sections, data } = useOutletContext();
	const { newsData } = data;
	const { html } = sections.main;

	const tabs = useImageGalleryTabs();
	const hasGalleryTabs = tabs.length > 0;

	return (
		<AppContent>
			<HeroBanner/>
			<PageHeadline title={headline || title} subtitle={subtitle}/>
			<PageContainer>
				<HtmlParser html={html} maxWidth="lg"/>
			</PageContainer>
			{newsData.data?.length > 0 && (
				<NewsSection
					title={messages?.pages?.home?.newsSection?.title}
					cards={newsData.data}
				/>
			)}
			<ContactSection/>
			{hasGalleryTabs && (
				<PageContainer gutterTop>
					<PageHeadline subtitle={messages.pages.home.imageGalleries.title} centered/>
					<ImageGalleryTabs tabs={tabs}/>
				</PageContainer>
			)}
		</AppContent>
	);
}
