import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses } from 'styles';
import Container from 'react-app/components/Container';

const useClasses = createClasses((theme, {disableGuttersX, disableGutterBottom, spacingType = 'gutter', centered, gutterTop, marginTop, flexGrow}) => ({
	root: {
		...theme.mixins.flexContainer,
		flexGrow,
		position: 'relative',
		marginX: centered ? 'auto' : 'initial',
		maxWidth: theme.config.containerMaxWidth,
		...(!disableGuttersX && ({
			paddingX: theme.config.containerSpacing
		})),
		...(!disableGutterBottom && ({
			'&:not(:last-child)': {
				marginBottom: theme.config[`${spacingType}Spacing`]
			}
		})),
		...(gutterTop && {
			marginTop: marginTop || theme.config.containerSpacing
		}),
		...(centered && {
			alignItems: 'center'
		})
	}
}), {
	name: 'PageContainer'
});

function PageContainer(props) {
	const {
		classes: classesProp,
		className,
		children,
		spacingType,
		disableGuttersX,
		disableGutterBottom,
		centered,
		gutterTop,
		marginTop,
		flexGrow,
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<Container
			maxWidth={false}
			{...rest}
			disableGutters={disableGuttersX}
			className={classes.root}
		>
			{children}
		</Container>
	);
}

PageContainer.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	disableGutterBottom: PropTypes.bool,
	disableGuttersX: PropTypes.bool,
	centered: PropTypes.bool,
	spacingType: PropTypes.oneOf(['container', 'gutter'])
};

export default React.memo(PageContainer);
