import * as React from 'react';
import CarouselSection from 'components/CarouselSection';
import NewsCarousel from './NewsCarousel';

function NewsSection(props) {
	const { CardProps, ...rest } = props;
	return (
		<CarouselSection
			{...rest}
			CarouselComponent={NewsCarousel}
			CardProps={{
				dialogMode: true,
				...CardProps
			}}
		/>
	);
}

export default React.memo(NewsSection);
