import Stack from '@mui/material/Stack';
import PageSection from 'components/PageSection';
import ContactIcon from '@mui/icons-material/QuestionAnswer';
import DirectionsIcon from '@mui/icons-material/Directions';
import Button from 'components/Button';
import WhatsAppButton from 'components/WhatsAppButton';
import ContactSection from 'components/ContactSection';
import imageSrcMichaela from 'images/cards/team/michaela_nejedla.jpg';
import imageSrcJan from 'images/cards/team/jan_riha.jpg';
import { appData } from 'config/data.cjs';

const { messages } = appData;

const PageSctionProps = {
	title: messages.pages.home.contactSection.title,
	PageHeadlineProps: {
		centered: true
	}
};

export default function CzechContactSection(props) {
	const { noContactFormBtn, ...rest } = props;

	return (
		<PageSection {...PageSctionProps} {...rest}>
			<Stack
				justifyContent="center"
				alignItems="center"
				direction={{tablet: 'row'}}
				flexWrap="wrap"
				rowGap={4}
				columnGap={8}
				marginX="auto"
				marginTop={{xs: 1, md: 2}}
			>
				<ContactSection
					name="Michaela Nejedla"
					email="m.nejedla@holzweidauer.de"
					mobile="+49 151 508 961 64"
					imageSrc={imageSrcMichaela}
				/>
				<ContactSection
					name="Jan Riha"
					email="j.riha@holzweidauer.de"
					mobile="00420 775 867 077"
					imageSrc={imageSrcJan}
				/>
			</Stack>
			<Stack direction={{tablet: 'row'}} gap={2} marginX="auto" marginTop={4} marginBottom={{xs: 1, md: 0}}>
				<Button
					size="large"
					variant="contained"
					color="primary"
					href="https://tinyurl.com/yfdzphfc"
					startIcon={<DirectionsIcon/>}
				>
					{messages.button.directions}
				</Button>
				<WhatsAppButton size="large">
					{messages.button.whatsAppChat}
				</WhatsAppButton>
				{!noContactFormBtn && (
					<Button
						size="large"
						variant="contained"
						href={appData.pages.contact.pathname + '#contactForm'}
						startIcon={<ContactIcon/>}
					>
						{messages.button.contactFrom}
					</Button>
				)}
			</Stack>
		</PageSection>
	);
}
