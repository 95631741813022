import * as React from 'react';
import PropTypes from 'prop-types';
import ScrollableCards from 'components/ScrollableCards';
import NewsCard from 'components/News/NewsCard';
import EventCard from 'components/Events/EventCard';

const defaultProps = {
	cardSizes: {
		xs: 300,
		phone: 360,
		sm: 480,
		desktop: 440
	}
};

function NewsCarousel(props) {
	const {
		cards: cardsData = [],
		CardProps,
		CardComponent = NewsCard,
		cardSizes = defaultProps.cardSizes,
		...rest
	} = props;

	const cards = (
		cardsData.map((cardData, key) => (
			cardData?.isEvent && !props.CardComponent ? (
				<EventCard {...CardProps} {...cardData} key={key}/>
			) : (
				<CardComponent {...CardProps} {...cardData} key={key}/>
			)
		))
	);

	return (
		<ScrollableCards {...rest} cardSizes={cardSizes}>
			{cards}
		</ScrollableCards>
	);
}

NewsCarousel.propTypes = {
	cards: PropTypes.array.isRequired,
	CardsProps: PropTypes.object,
	CardComponent: PropTypes.elementType
};

export default React.memo(NewsCarousel);
